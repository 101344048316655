import React, { useState } from 'react';
import { Heart, Activity, AlertCircle } from 'lucide-react';

const NextGenHealthcare = () => {
  const [patientData, setPatientData] = useState('');
  const [diagnosis, setDiagnosis] = useState(null);
  const [treatmentPlan, setTreatmentPlan] = useState(null);

  const generateDiagnosisAndTreatment = () => {
    // Simulated LLM-generated diagnosis and treatment plan
    setDiagnosis({
      condition: "Type 2 Diabetes",
      confidence: 0.92,
      relatedFactors: [
        "Elevated blood glucose levels",
        "Family history of diabetes",
        "Sedentary lifestyle",
        "Overweight BMI"
      ]
    });

    setTreatmentPlan([
      "Prescribed medication: Metformin 500mg twice daily",
      "Dietary changes: Low-carb, high-fiber diet",
      "Exercise regimen: 30 minutes of moderate activity 5 days a week",
      "Regular blood glucose monitoring",
      "Follow-up appointment in 3 months"
    ]);
  };

  return (
    <div>
      <div className="flex items-center mb-6">
        <Heart size={32} className="mr-4 text-red-400" />
        <h2 className="text-3xl font-bold">Next-Generation Healthcare</h2>
      </div>
      <p className="mb-6">
        LLMs are poised to transform healthcare by assisting in diagnosis, 
        treatment planning, and personalized medicine, potentially improving 
        patient outcomes and reducing healthcare costs.
      </p>
      <div className="bg-gray-700 p-6 rounded-lg mb-6">
        <h3 className="text-xl font-semibold mb-4">Interactive Demo: AI Medical Assistant</h3>
        <textarea
          value={patientData}
          onChange={(e) => setPatientData(e.target.value)}
          placeholder="Enter patient symptoms, medical history, and test results..."
          className="w-full p-2 mb-4 text-gray-800 rounded h-32"
        />
        <button
          onClick={generateDiagnosisAndTreatment}
          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Generate Diagnosis & Treatment Plan
        </button>
        {diagnosis && treatmentPlan && (
          <div className="mt-4">
            <div className="bg-gray-600 p-4 rounded mb-4">
              <h4 className="font-semibold mb-2 flex items-center">
                <Activity className="mr-2" /> Diagnosis
              </h4>
              <p className="mb-2"><strong>Condition:</strong> {diagnosis.condition}</p>
              <p className="mb-2"><strong>Confidence:</strong> {(diagnosis.confidence * 100).toFixed(1)}%</p>
              <p className="mb-2"><strong>Related Factors:</strong></p>
              <ul className="list-disc pl-6">
                {diagnosis.relatedFactors.map((factor, index) => (
                  <li key={index}>{factor}</li>
                ))}
              </ul>
            </div>
            <div className="bg-gray-600 p-4 rounded">
              <h4 className="font-semibold mb-2 flex items-center">
                <AlertCircle className="mr-2" /> Treatment Plan
              </h4>
              <ul className="list-disc pl-6">
                {treatmentPlan.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <p>
        This demo illustrates how future LLMs could revolutionize healthcare by:
      </p>
      <ul className="list-disc pl-6 mt-2">
        <li>Analyzing complex patient data to assist in accurate diagnosis</li>
        <li>Generating personalized treatment plans based on the latest medical research</li>
        <li>Providing confidence levels to support clinical decision-making</li>
        <li>Identifying related factors that may contribute to the patient's condition</li>
      </ul>
      <p className="mt-4">
        By leveraging LLMs in healthcare, we can potentially improve diagnostic 
        accuracy, streamline treatment planning, and provide more personalized 
        care to patients. However, it's crucial to note that these AI systems 
        would be designed to assist healthcare professionals, not replace them, 
        ensuring that human expertise and judgment remain central to patient care.
      </p>
    </div>
  );
};

export default NextGenHealthcare;