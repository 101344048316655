import React, { useState } from 'react';
import { BookOpen, Check, X } from 'lucide-react';

const PersonalizedEducation = () => {
  const [studentProfile, setStudentProfile] = useState('');
  const [learningPath, setLearningPath] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [feedback, setFeedback] = useState('');

  const generateLearningPath = () => {
    // Simulated LLM-generated learning path
    setLearningPath([
      "Introduction to AI concepts",
      "Basic Python programming",
      "Data structures and algorithms",
      "Machine learning fundamentals",
      "Neural networks and deep learning"
    ]);
    setCurrentQuestion({
      question: "What is the primary goal of supervised learning?",
      options: [
        "To cluster data points",
        "To predict labels for new data",
        "To reduce dimensionality",
        "To generate new data samples"
      ],
      correctAnswer: 1
    });
  };

  const handleAnswer = (index) => {
    if (index === currentQuestion.correctAnswer) {
      setFeedback("Correct! Great job understanding the concept.");
    } else {
      setFeedback("Not quite. Let's review this concept together.");
    }
  };

  return (
    <div>
      <div className="flex items-center mb-6">
        <BookOpen size={32} className="mr-4 text-yellow-400" />
        <h2 className="text-3xl font-bold">Personalized Education</h2>
      </div>
      <p className="mb-6">
        LLMs are revolutionizing education by creating tailored learning experiences 
        that adapt to each student's needs, preferences, and learning style.
      </p>
      <div className="bg-gray-700 p-6 rounded-lg mb-6">
        <h3 className="text-xl font-semibold mb-4">Interactive Demo: AI Tutor</h3>
        <input
          type="text"
          value={studentProfile}
          onChange={(e) => setStudentProfile(e.target.value)}
          placeholder="Describe the student's background and goals..."
          className="w-full p-2 mb-4 text-gray-800 rounded"
        />
        <button
          onClick={generateLearningPath}
          className="bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
        >
          Generate Personalized Learning Path
        </button>
        {learningPath && (
          <div className="mt-4">
            <h4 className="font-semibold mb-2">Recommended Learning Path:</h4>
            <ul className="list-disc pl-6 mb-4">
              {learningPath.map((topic, index) => (
                <li key={index}>{topic}</li>
              ))}
            </ul>
            {currentQuestion && (
              <div className="bg-gray-600 p-4 rounded">
                <h5 className="font-semibold mb-2">Practice Question:</h5>
                <p className="mb-2">{currentQuestion.question}</p>
                {currentQuestion.options.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleAnswer(index)}
                    className="block w-full text-left p-2 mb-2 bg-gray-500 hover:bg-gray-400 rounded"
                  >
                    {option}
                  </button>
                ))}
                {feedback && (
                  <p className="mt-4 font-semibold">
                    {feedback.startsWith("Correct") ? (
                      <Check className="inline mr-2 text-green-400" />
                    ) : (
                      <X className="inline mr-2 text-red-400" />
                    )}
                    {feedback}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <p>
        This demo showcases how future LLMs could revolutionize education by:
      </p>
      <ul className="list-disc pl-6 mt-2">
        <li>Creating personalized learning paths based on individual student profiles</li>
        <li>Generating relevant practice questions to reinforce learning</li>
        <li>Providing immediate, contextual feedback to guide the learning process</li>
        <li>Adapting the curriculum in real-time based on the student's performance and engagement</li>
      </ul>
      <p className="mt-4">
        By leveraging LLMs in education, we can create more engaging, effective, and 
        tailored learning experiences that cater to each student's unique needs and 
        learning style, potentially transforming the future of education.
      </p>
    </div>
  );
};

export default PersonalizedEducation;