import React from 'react';
import { Brain } from 'lucide-react';

const Introduction = () => {
  return (
    <div className="text-center">
      <Brain size={64} className="mx-auto mb-6 text-blue-400" />
      <h2 className="text-3xl font-bold mb-4">The Future of LLMs</h2>
      <p className="mb-6">
        Large Language Models (LLMs) are poised to revolutionize various industries, 
        pushing the boundaries of what's possible in human-AI collaboration.
      </p>
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-gray-700 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Enhanced Creativity</h3>
          <p>LLMs as co-creators in design and art</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Advanced Analytics</h3>
          <p>Complex financial modeling and predictions</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Personalized Learning</h3>
          <p>Adaptive education systems for all ages</p>
        </div>
        <div className="bg-gray-700 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Healthcare Innovation</h3>
          <p>AI-assisted diagnostics and treatment plans</p>
        </div>
      </div>
    </div>
  );
};

export default Introduction;