import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TrendingUp } from 'lucide-react';

const FinancialModeling = () => {
  const [scenario, setScenario] = useState('');
  const [prediction, setPrediction] = useState(null);

  const generatePrediction = () => {
    // Simulated LLM-generated financial prediction
    const data = Array.from({ length: 12 }, (_, i) => ({
      month: `Month ${i + 1}`,
      predictedValue: Math.floor(Math.random() * 100) + 50,
      actualValue: Math.floor(Math.random() * 100) + 50,
    }));
    setPrediction(data);
  };

  return (
    <div>
      <div className="flex items-center mb-6">
        <TrendingUp size={32} className="mr-4 text-green-400" />
        <h2 className="text-3xl font-bold">Advanced Financial Modeling</h2>
      </div>
      <p className="mb-6">
        LLMs are transforming financial analysis by processing vast amounts of data 
        and generating sophisticated models and predictions.
      </p>
      <div className="bg-gray-700 p-6 rounded-lg mb-6">
        <h3 className="text-xl font-semibold mb-4">Interactive Demo: AI Financial Analyst</h3>
        <input
          type="text"
          value={scenario}
          onChange={(e) => setScenario(e.target.value)}
          placeholder="Describe a financial scenario..."
          className="w-full p-2 mb-4 text-gray-800 rounded"
        />
        <button
          onClick={generatePrediction}
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Generate Financial Prediction
        </button>
        {prediction && (
          <div className="mt-4">
            <h4 className="font-semibold mb-2">Generated Financial Prediction:</h4>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={prediction}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="predictedValue" stroke="#8884d8" name="Predicted" />
                <Line type="monotone" dataKey="actualValue" stroke="#82ca9d" name="Actual" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
      <p>
        This demo illustrates how future LLMs could analyze complex financial scenarios 
        and generate predictive models, assisting in decision-making and risk assessment.
      </p>
    </div>
  );
};

export default FinancialModeling;