import React, { useState } from 'react';
import { Palette } from 'lucide-react';

const AugmentedCreativity = () => {
  const [prompt, setPrompt] = useState('');
  const [generatedDesign, setGeneratedDesign] = useState('');

  const generateDesign = () => {
    // Simulated LLM response
    const designs = [
      "A futuristic cityscape with floating gardens and solar-powered skyscrapers",
      "An abstract representation of human emotions using vibrant colors and fluid shapes",
      "A biomimetic furniture design inspired by the structure of honeycombs",
      "A sleek, minimalist logo combining the letters 'A' and 'I' in a unique way"
    ];
    setGeneratedDesign(designs[Math.floor(Math.random() * designs.length)]);
  };

  return (
    <div>
      <div className="flex items-center mb-6">
        <Palette size={32} className="mr-4 text-pink-400" />
        <h2 className="text-3xl font-bold">Augmented Creativity in Design</h2>
      </div>
      <p className="mb-6">
        LLMs are revolutionizing the creative process by offering intelligent 
        suggestions and generating novel ideas based on textual prompts.
      </p>
      <div className="bg-gray-700 p-6 rounded-lg mb-6">
        <h3 className="text-xl font-semibold mb-4">Interactive Demo: AI Design Assistant</h3>
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter a design prompt..."
          className="w-full p-2 mb-4 text-gray-800 rounded"
        />
        <button
          onClick={generateDesign}
          className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded"
        >
          Generate Design Concept
        </button>
        {generatedDesign && (
          <div className="mt-4 p-4 bg-gray-600 rounded">
            <h4 className="font-semibold mb-2">Generated Design Concept:</h4>
            <p>{generatedDesign}</p>
          </div>
        )}
      </div>
      <p>
        This demo simulates how future LLMs could assist designers by generating 
        innovative concepts based on text prompts, enhancing the creative process 
        and pushing the boundaries of design thinking.
      </p>
    </div>
  );
};

export default AugmentedCreativity;