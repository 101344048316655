import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Introduction from './components/Introduction';
import AugmentedCreativity from './components/AugmentedCreativity';
import FinancialModeling from './components/FinancialModeling';
import PersonalizedEducation from './components/PersonalizedEducation';
import NextGenHealthcare from './components/NextGenHealthcare';
import Conclusion from './components/Conclusion';

const App = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    Introduction,
    AugmentedCreativity,
    FinancialModeling,
    PersonalizedEducation,
    NextGenHealthcare,
    Conclusion
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const CurrentSlideComponent = slides[currentSlide];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">The Future of LLMs: Novel Use Cases</h1>
      <div className="max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-lg p-8">
        <CurrentSlideComponent />
      </div>
      <div className="flex justify-between mt-8">
        <button
          onClick={prevSlide}
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
        >
          <ChevronLeft className="mr-2" /> Previous
        </button>
        <button
          onClick={nextSlide}
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
        >
          Next <ChevronRight className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default App;